.react-datepicker-wrapper {
  display: flex;
  margin: 8px 0 8px 0;
}

.react-datepicker__input-container input {
  box-sizing: border-box;
  display: block;
  width: 100%;
  padding: 16.5px 14px;
  border-radius: 6px;
  border: 1px solid #bdbdbd;
}

.react-datepicker__input-container:focus-visible
  .react-datepicker__input-container:focus-within
  input:focus-visible
  input:focus-within {
  border: 1px solid #fb973a;
}

.react-datepicker-popper {
  z-index: 9999;
}
